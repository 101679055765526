import type { HeadersFunction, LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'

import social from '@/assets/social.png?url'
import SiteFooter from '@/components/SiteFooter'
import SiteHeader from '@/components/SiteHeader'
import { cacheControl } from '@/http.server'
import { findCareers } from '@/models/career.server'
import { pageHelper } from '@/request'

type LoaderData = typeof loader

export async function loader({ request }: LoaderFunctionArgs) {
  const { root } = pageHelper(request)

  const careers = findCareers()

  return json(
    {
      careers: careers,
      root: root
    },
    {
      headers: {
        'Cache-Control': cacheControl
      }
    }
  )
}

export const headers: HeadersFunction = ({ parentHeaders }) => ({
  'Cache-Control': parentHeaders.get('Cache-Control') ?? ''
})

export const meta: MetaFunction<LoaderData> = ({ data }) => {
  if (!data) return []

  const { root } = data

  const title = 'Careers — PlanetScale'
  const description = 'Building the database of the future, together.'
  const image = `${root}${social}`

  return [
    { title },
    { name: 'description', content: description },
    { property: 'og:url', content: 'https://planetscale.com/careers' },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    { property: 'og:image', content: image },
    { property: 'og:description', content: description },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:site', content: '@PlanetScale' },
    { property: 'twitter:creator', content: '@PlanetScale' },
    { property: 'twitter:url', content: 'https://planetscale.com/careers' },
    { property: 'twitter:title', content: title },
    { property: 'twitter:description', content: description },
    { property: 'twitter:image', content: image },
    { tagName: 'link', rel: 'canonical', href: 'https://planetscale.com/careers' }
  ]
}

export default function Careers() {
  const { careers } = useLoaderData<LoaderData>()

  return (
    <>
      <SiteHeader />

      <main className='container my-6 max-w-7xl px-3 sm:px-5 lg:px-12'>
        <section>
          <h1>Careers at PlanetScale</h1>

          <h2>Global hiring guidelines</h2>

          <p>
            Our philosophy is to attract the best talent and to support them doing amazing things where they are the
            most comfortable and productive. We have a highly distributed, remote forward team with employees across the
            United States and the globe. In order to achieve this goal, we will either hire directly to PlanetScale or
            partner with a Professional Employment Organization (PEO).
          </p>

          <p>
            Due to business requirements, we may need to hire in a certain country or region. The job post on our career
            site will identify whether the role is location specific or can be remote (for example, Remote - United
            States or Remote - Americas)
          </p>

          <p>
            As we are a company based in the United States, however, we are required to comply with suggested hiring
            practices for sanctioned countries. We will not be able to consider candidates from several countries due to
            United States countrywide embargoes banning any business with anyone in those countries. This list currently
            includes: North Korea, Cuba, Iran, Syria, Sudan, and the Crimean region of Ukraine. Although not subject to
            the same type of US embargo, there are other practical and legal considerations barring us from hiring or
            contracting with a resident of Mainland China.
          </p>

          <p>
            There is another group of countries where sanctions are not countrywide, but instead prohibit us from hiring
            certain listed individuals. Individuals end up on these sanctions lists for human rights violations,
            terrorism, international narcotics trafficking, and genocide.
          </p>

          <p>
            Our Talent Acquisition team will be responsible for ensuring the appropriate vetting of candidates is
            completed and we are in compliance with any legal requirements - United States and/or international. We will
            notify all applicants of our decision to not consider them further as a candidate or to engage them in our
            interview process.
          </p>

          <h2>Current openings</h2>

          <div className='grid grid-cols-1 text-left'>
            {careers.map((career) => (
              <Link
                className='-m-[0.5px] flex flex-col border p-3 sm:flex-row sm:items-center sm:justify-between'
                key={career.slug}
                to={`/careers/${career.slug}`}
              >
                <span className='font-semibold'>{career.title}</span>
                <span className='text-primary'>{career.location}</span>
              </Link>
            ))}
          </div>
        </section>
      </main>

      <SiteFooter />
    </>
  )
}
